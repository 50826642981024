$logo-zoom: 2;
$logo-height: 5.875rem * $logo-zoom;
$table-padding: 2rem;
$name-col-max-width: 34rem;

.table-wrapper {
  margin-bottom: 5rem;
  position: relative;
  padding: $table-padding;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary-2);
  width: 100%;

  // This will account for the space needed to accomodate
  // the logo.
  margin-top: $logo-height / 2;
}

.logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  transform: translateY(-50%);
  color: var(--black);
  width: 7.5rem * $logo-zoom;
  background-color: var(--black);
}

.table {
  color: var(--primary-2);
  font-family: "ChubbyChoo";
  font-size: 2rem;
  font-weight: 400;
  text-align: left;
  table-layout: fixed;

  tr:not(first-child):first-of-type {
    // color: var(--primary-1);
    min-width: 1rem;
    animation: flashy 1s infinite;
  }

  // Rank col
  tr td:first-of-type {
    width: 10%;
    text-align: left;
  }

  // Name col
  tr td:nth-of-type(2) {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Score col
  tr td:last-of-type {
    width: 30%;
    text-align: right;
  }

  td + td {
    width: auto;
  }
}

@media (min-width: theme("screens.lg")) {
  .table-wrapper {
    // Reset
    border-width: 0;
    border-top-width: 2px;
  }
}

@keyframes flashy {
  from {
    color: var(--primary-1);
  }
  33% {
    color: blue;
  }
  66% {
    color: var(--white);
  }
  to {
    color: var(--secondary-1);
  }
}


.allStars{
  color: #ff8e06 !important;
  font-weight: bold;
  margin-top: 2rem;
}
.highScoreTitle{
  color: #05bed6 !important;
  font-weight: bold;
  margin-top: 2rem;
  text-transform: uppercase;
}
.mt_5{
  margin-top: 1.25rem !important;
}
.borderStyle{
  border-color: #05bed6;
    border-width: 1px;
    width: 75%;
    margin-left: 10%;
    position: absolute;
    // margin-right: auto;
    // font-weight: bold;
}