.title {
  font-family: GALACTIC VANGUARDIAN NCV;
}

.subtitle,
.header,
.header *,
.tagline,
.button {
  font-family: "ChubbyChoo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
