.gameframe {
  position: relative;
  margin: auto;
  width: 800px;
  max-width: 100%;
  border: 2px solid var(--primary-2);
  margin-bottom: 2rem;

  .game,
  .logo-wrapper,
  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .logo-wrapper {
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .loading {
    pointer-events: none;
    z-index: 2;
    transition: opacity 10s;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.game {
  width: 100%;
  height: 100%;
  border: 1rem solid var(--black);
}

.featured-image {
  z-index: 1;
  cursor: pointer;
  opacity: 1;
  transition: opacity 5s;
  height: 100%;
}

@media screen(sm) {
  .gameframe {
    max-height: 100%;
  }
}

.spin {
  animation-name: spin;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-visible {
  &.gameframe {
    border: 0;
  }

  .featured-image,
  .loading {
    opacity: 0;
    pointer-events: none;
  }

  iframe {
    border: 2px solid var(--primary-2);
  }
}
